import React from 'react'


const SectionTitleS3 = (props) => {
    return(
        <div className="wpo-giftbox-title-s3">
            <div className="giftbox-title-icon">
                <i className="fi flaticon-dove"></i>
            </div>
            <h2>{props.MainTitle}</h2>
        </div>
    )
}

export default SectionTitleS3;