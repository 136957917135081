import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2';
import Hero4 from '../../components/hero4/Hero4';



const InvitationPage =() => {

    return(
        <Fragment>
            <Navbar2/>
            <Hero4/>
        </Fragment>
    )
};

export default InvitationPage;