import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import blog3 from '../../images/blog-details/comments-author/img-1.jpg';
import blog4 from '../../images/blog-details/comments-author/img-2.jpg';
import blog5 from '../../images/blog-details/comments-author/img-3.jpg';
import blog6 from '../../images/blog-details/author.jpg';
import gl1 from '../../images/blog-details/1.jpg';
import gl2 from '../../images/blog-details/2.jpg';
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom';
import BlogSidebar from '../../components/BlogSidebar';
import {db} from '../../firebase/firebase';
import {collection, getDocs} from "firebase/firestore";

const BlogSingle = (props) => {

    const { id } = useParams();
    const BlogDetails = blogs.find(item => item.id === id);
    const [getDataArray, setDataArray] = useState([]);
    const [createForm, setForm] = useState({});   
    const clock = Date.now(); // new Date()
    const dateNow = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(clock);


   const changeHandler = e => {
        console.log(e.target.name, e.target.value);
        setForm({ 
            ...createForm,
            [e.target.name]: e.target.value
        })        
        /*if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }*/
    };
    const submitHandler = (e) => {
        e.preventDefault()
        postBlog();
    }

   useEffect(()=>{
    ;(async()=>{
    const userCollectionRef = collection(db ,"Blog");
    //const getBlogs = async () => {
    const getBlogs = await getDocs(userCollectionRef);
    const docs = getBlogs.docs.map((doc)=> {
        const data = doc.data()
        data.id = doc.id;
        return data;
        });
        setDataArray(docs);
        console.log('data: ' , docs); 
            //console.log()
          //  setData(data.docs.map((doc) => ({ ...doc.data()}))); // spredding operator
        })()
        //getBlogs();
   },[]);

    const postBlog = async () => {
        console.log("postBlog");
        db.collection('Blog').add({
            Email: createForm.Email,
            Name: createForm.Name,
            Comments: createForm.Comments,
            Timestamp: dateNow
        })
        setForm({
            Comments: '',
            Email: '',
            Name: '',          
        })
    }

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={BlogDetails.blogSingleImg} alt="" />
                                </div>
                                <br/>
                                <h2>{BlogDetails.title}</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful.</p>
                                <blockquote>
                                    Combined with a handful of model sentence structures, generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                                </blockquote>
                                <p>I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself,</p>
                                <div className="entry-meta">
                                    <ul>
                                        <li><i className="fi flaticon-user"></i> By <Link to="/blog-single/1">{BlogDetails.author}</Link> </li>
                                        <li><i className="fi flaticon-comment-white-oval-bubble"></i> Comments {BlogDetails.comment}</li>
                                        <li><i className="fi flaticon-calendar"></i> {BlogDetails.create_at}</li>
                                    </ul>
                                </div>
                                <div className="gallery">
                                    <div>
                                        <img src={gl1} alt="" />
                                    </div>
                                    <div>
                                        <img src={gl2} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <span>Share: </span>
                                    <ul>
                                        <li><Link to="/blog-single/1">Planning</Link></li>
                                        <li><Link to="/blog-single/1">photography</Link></li>
                                        <li><Link to="/blog-single/1">creative</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tag-share-s2 clearfix">
                                <div className="tag">
                                    <span>Share: </span>
                                    <ul>
                                        <li><Link to="/blog-single/1">facebook</Link></li>
                                        <li><Link to="/blog-single/1">twitter</Link></li>
                                        <li><Link to="/blog-single/1">linkedin</Link></li>
                                        <li><Link to="/blog-single/1">pinterest</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="author-box">
                                <div className="author-avatar">
                                    <Link to="/blog-single/1" target="_blank"><img src={blog6} alt="" /></Link>
                                </div>
                                <div className="author-content">
                                    <Link to="/blog-single/1" className="author-name">Author: Jenny Watson</Link>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.</p>
                                    <div className="socials">
                                        <ul className="social-link">
                                            <li><Link to="/blog-single/1"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/blog-single/1"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/blog-single/1"><i className="ti-linkedin"></i></Link></li>
                                            <li><Link to="/blog-single/1"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="more-posts">
                                <div className="previous-post">
                                    <Link to="/blog">
                                        <span className="post-control-link">Previous Post</span>
                                        <span className="post-name">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="/blog-left-sidebar">
                                        <span className="post-control-link">Next Post</span>
                                        <span className="post-name">Dignissimos ducimus qui blanditiis praesentiu deleniti atque corrupti quos dolores</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">Comments</h3>
                                    <ol className="comments">
                                        {getDataArray.map((question) => {
                                            return (                                    
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>                                                
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>{question.Name} <span className="comments-date">{question.Timestamp}</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>{question.Comments}</p>
                                                        <div className="comments-reply">
                                                               {/* <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </li>
                                        );
                                        })}
                                        {/*
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>                                                
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2022
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of the truth, </p>
                                                        <div className="comments-reply">
                                                               {/* <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}                                            
                                            {/* <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog4} alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>Lily Watson <span className="comments-date">January
                                                                        12,2022 At 9.00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>I will give you a complete account of the system,
                                                                        and expound the actual teachings of the great
                                                                        explorer of the truth, </p>
                                                                    <div className="comments-reply">
                                                                     {/* <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>John Abraham <span className="comments-date">January
                                                                                12,2022 At 9.00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>I will give you a complete account of the
                                                                                system, and expound the actual teachings
                                                                                of the great explorer of the truth, </p>
                                                                            <div className="comments-reply">
                                                                              {/* <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                </ul>                                       
                                        </li>*/}                                      
                                    </ol>
                                </div>
                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Post Comments or Questions</h3>
                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                        <div className="">
                                        <input value={createForm.Name} type="text" name="Name" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} placeholder="Name" />
                                        <input value={createForm.Email} type="text" name="Email" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} placeholder="Email" />
                                        </div>
                                        <div className="form-textarea">
                                            {/*<textarea id="comment" placeholder="Write Your Comments..."></textarea>*/}
                                            <textarea value={createForm.Comments} id="comments" name="Comments" onBlur={(e) => changeHandler(e)} onChange={(e) => changeHandler(e)} placeholder="Write Your Comments" ></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            {/*<input placeholder="Website" type="url" */}                        
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Post Comment" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>
    )
}

export default BlogSingle;
