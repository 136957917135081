import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2';
import Hero4 from '../../components/hero4/Hero4';
import GiftBox from '../../components/GiftBox/giftbox';

const HonneyMoon =() => {

    return(
        <Fragment>
            <Navbar2/>            
            <GiftBox/>
        </Fragment>
    )
};

export default HonneyMoon;